import React, { Component } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Footer.module.scss';

class Footer extends Component {
  render() {
    const { config } = this.props;
    const { copyright } = config;

    return (
      <footer>
        <div>
          <p>
            <a href="https://www.facebook.com/spreadsheep/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', 'facebook']} />
              &nbsp; Facebook
            </a>
          </p>
          <p>
            <a href="https://twitter.com/spreadsheep_app" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', 'twitter']} />
              &nbsp; Twitter
            </a>
          </p>
          <p>
            <a href="https://medium.com/spreadsheep" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['fab', 'medium']} />
              &nbsp; Blog
            </a>
          </p>
          <p>
            <Link to="/questions-frequentes">FAQ</Link>
          </p>
          <p>
            <a
              href="https://desk.zoho.com/portal/spreadsheep/kb/spreadsheep"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aide
            </a>
          </p>
          <p>
            <Link to="/mentions-legales">Mentions légales</Link>
          </p>
          <p>
            <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
          </p>
          <p>contact@spreadsheep.io</p>
          <p>+33 1 72 77 00 42</p>
          <p>{copyright}</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
