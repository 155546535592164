
import React, { Component, useState } from 'react';
import { Link } from 'gatsby';

import Button from '../Button/Button';

// import LogoTransparentLight from '../../../content/img/spreadsheep-logo-transparent-light.svg';
import LogoTransparentLightIcon from '../../../content/img/spreadsheep-logo-icon-transparent.svg';

import styles from './header.module.scss';

const HeaderButton = ({ children, isCurrent }) => {
  return (<Button active={isCurrent}>{children}</Button>);
};
const Header = () => {
  const [displayMenu, toggleMenu] = useState(false);
  return (
    <nav className={styles.navbar} role="navigation">
      <div className={styles.navbarHeader}>
        <a className={styles.logo} href="/">
          <img className="img-responsive" alt="logo Spreadsheep" src={LogoTransparentLightIcon} />
          <span>Spreadsheep</span>
        </a>
        <button
          type="button"
          className={`${styles.navbarToggle} ${displayMenu ? styles.change : ''}`}
          onClick={() => {
            toggleMenu(!displayMenu);
          }}
        >
          <div className={styles.bar1} />
          <div className={styles.bar2} />
          <div className={styles.bar3} />
        </button>
      </div>

      <div className={`${styles.menu} ${displayMenu ? styles.displayMenu : ''}`} id="navbar">
        <ul className="">
          <li>
            <Link to="/product">
              <HeaderButton>Produit</HeaderButton>
            </Link>
          </li>
          <li>
            <Link to="/solutions">
              <HeaderButton>Solutions</HeaderButton>
            </Link>
          </li>
          <li>
            <Link to="/pricing">
              <HeaderButton>Tarification</HeaderButton>
            </Link>
          </li>
          <li>
            <a href="https://beta.spreadsheep.io">
              <Button dark>Connexion</Button>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;

//
// <NavItem>
//   <Link to="/">
//     <img
//       className="img-responsive visible-sm-block"
//       alt="logo Spreadsheep Icon"
//       src="img/spreadsheep-logo-icon-transparent.svg"
//     />
//   </Link>
// </NavItem>
