import React from 'react';
import { Helmet } from 'react-helmet';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck, faCheckCircle, faPencilAlt, faClock, faLock, faUserPlus, faUsers, faFileImport,
  faPlus, faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import {
  faTwitter, faMedium, faFacebook,
} from '@fortawesome/free-brands-svg-icons';

import config from '../../data/SiteConfig';
import './index.scss';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Hotjar from '../components/Hotjar/Hotjar';

library.add(faCheck, faCheckCircle, faPencilAlt, faFilePdf, faClock, faLock,
  faUserPlus, faUsers, faFileImport, faPlus, faArrowLeft,
  faTwitter, faMedium, faFacebook);

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <Hotjar />
        </Helmet>
        <Header />
        {children}
        <Footer config={config} />
      </div>
    );
  }
}
