const config = {
  siteTitle: 'Spreadsheep - database as a service for end users', // Site title.
  siteTitleShort: 'Spreadsheep', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt:
    'Spreadsheep is a simple database for small businesses looking for rapid application development easily usable and shareable by end users', // Alternative site title for SEO.
  siteLogo: '/logos/logo-1024.png', // Logo used for SEO and manifest.
  siteUrl: 'https://spreadsheep.io', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Spreadsheep - collaboration made easy for small teams looking for database application developpment tools.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: '', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-109047156-1', // GA tracking ID.
  postDefaultCategoryID: 'Tech', // Default category for posts.
  postDefaultIcon: './img/job-recruiting.svg',
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  twitter: '@spreadsheep_app', // Optionally renders "Follow Me" in the UserInfo segment.
  copyright: 'Copyright KADATA © 2020.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  favicon: 'content/img/spreadsheep-logo-icon-transparent.svg',
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') { config.siteUrl = config.siteUrl.slice(0, -1); }

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/') { config.siteRss = `/${config.siteRss}`; }

module.exports = config;
