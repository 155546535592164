import React from 'react';


import styles from './button.module.scss';

const Button = ({
  children, active, type, bold, dark,
}) => {
  const className = `${styles.btn} ${active ? styles.active : ''} ${dark ? styles.btnDark : ''} ${bold ? styles.btnBold : ''}`;
  return (
    <button type={type} className={className}>
      {children}
    </button>
  );
};

export default Button;
